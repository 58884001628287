import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { DateTimeRangeValidationError } from '@mui/x-date-pickers-pro';
import { typoColor } from '../../../../theme/palette';

type DateRangeTimeValuePiece = Date | null;
type DateRangeTimeValue = [DateRangeTimeValuePiece, DateRangeTimeValuePiece];

type DateRangeTimePickerProps = {
  value: [DateRangeTimeValuePiece, DateRangeTimeValuePiece];
  onChange: (value: DateRangeTimeValue) => void;
  minDate?: Date;
  maxDate?: Date;
  onError?: (error: DateTimeRangeValidationError) => void;
  disableFuture?: boolean;
};

const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& .MuiMultiInputDateTimeRangeField-separator': {
    display: 'none',
  },
  '& .MuiTextField-root': {
    marginLeft: 0,
    '&:first-of-type': {
      '& .MuiInputBase-formControl': {
        maxWidth: 'fit-content',
        '& fieldset': {
          borderRadius: '12px 0 0 12px',
        },
      },
    },
    '&:last-of-type': {
      '& .MuiInputBase-formControl': {
        maxWidth: 'fit-content',
        '& fieldset': {
          borderRadius: '0 12px 12px 0',
        },
      },
    },
    '& .calendarIcon': {
      cursor: 'pointer',
    },
  },
  '& .MuiInputLabel-root': {
    opacity: '60%',
    top: '-6px',
  },
  '& .MuiInputLabel-shrink': {
    opacity: '100%',
    top: '0',
    span: {
      fontSize: '15px',
      color: typoColor.secondary,
    },
  },
  '& label': {
    textTransform: 'capitalize',
  },
});

export const DateRangeTimePicker: React.FC<DateRangeTimePickerProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  onError,
  disableFuture = false,
}) => {
  const { t } = useTranslation();
  return (
    <BoxStyled data-testid="dateTimeRangePickerContainer">
      <DateTimeRangePicker
        //@ts-ignore
        views={['day', 'month', 'year', 'hours', 'minutes']}
        value={value}
        onChange={onChange}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
        disableFuture={disableFuture}
        slotProps={{
          //@ts-ignore
          popper: () => ({
            'data-testid': 'calendar-tooltip',
          }),
          //@ts-ignore
          desktopPaper: () => ({
            'data-testid': 'calendar',
          }),
          //@ts-ignore
          calendarHeader: () => ({
            'data-testid': 'calendar header',
          }),
          //@ts-ignore
          day: () => ({
            'data-testid': 'calendar day',
          }),
          textField: ({ position }) => ({
            label:
              position === 'start' ? (
                <Typography variant={'text1Primary'} data-testid="fromLabel">
                  {t('common.from')}
                </Typography>
              ) : (
                <Typography variant={'text1Primary'} data-testid="toLabel">
                  {t('common.to')}
                </Typography>
              ),
            'data-testid': position === 'start' ? 'fromContainer' : 'toContainer',
            InputProps: {
              'data-testid': position === 'start' ? 'fromInput' : 'toInput',
              name: position === 'start' ? 'from' : 'to',
              endAdornment: (
                <DateRangeIcon
                  data-testid={position === 'start' ? 'fromIcon' : 'toIcon'}
                  className="calendarIcon"
                  sx={{ color: ({ palette }) => palette.text.secondary }}
                />
              ),
            },
          }),
        }}
        onError={onError}
      />
    </BoxStyled>
  );
};
