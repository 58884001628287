import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { greyColor, palette, typoColor } from '../../theme/palette';
import Button from '@mui/material/Button';
import { TextField } from '../../common/components/Inputs/TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Input from '@mui/material/Input';
import { useRSimUpload } from './useRSimUpload';
import { AutoHideSnackbar } from '../../common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../../common/Snackbar/AlertTitle';
import Stack from '@mui/material/Stack';

const DropAreaBox = styled(Box)({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  fontSize: '2rem',
});

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const BoxStyled = styled(Box)({
  position: 'relative',
  backgroundColor: greyColor[100],
  borderRadius: '12px',
  maxWidth: '512px',
  maxHeight: '187px',
  padding: '42px 20px 20px 20px',
  boxSizing: 'content-box',
  boxShadow: '0px 0px 5px 0px #00000014, 0px 1px 2px 0px #00000014',
});

const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',
});

const TextFieldStyled = styled(TextField)({
  backgroundColor: 'white',
  minWidth: '300px',
  mt: 1,
});

const ButtonStyled = styled(Button)(() => ({
  padding: '10px 16px',
}));

const TransparentButtonStyled = styled(Button)(() => ({
  backgroundColor: 'transparent !important',
  padding: '10px 16px',
}));

export const RSimManufacturingFilePage = () => {
  const { t } = useTranslation();
  const [dropAreaDisplayed, setDropAreaDisplayed] = useState(false);

  const {
    selectedFile,
    selectFile,
    uploadFile,
    uploading,
    importId,
    downloadOutput,
    importStatus,
    setImportStatus,
    clear,
  } = useRSimUpload();

  return (
    <>
      <Dropzone
        noClick
        onDrop={(acceptedFiles) => {
          if (selectedFile && (importId || importStatus)) {
            return;
          }
          setDropAreaDisplayed(false);
          selectFile(acceptedFiles[0]);
        }}
        onDragEnter={() => {
          if (selectedFile && (importId || importStatus)) {
            return;
          }
          setDropAreaDisplayed(true);
        }}
        onDragLeave={() => setDropAreaDisplayed(false)}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
              }}
              data-testid="dropzone"
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <Container>
                <BoxStyled {...getRootProps()} data-testid="RSimManufacturingFile page">
                  <Typography variant="text2Secondary" sx={{ ml: 4 }}>
                    {t('common.fileName')}
                  </Typography>

                  <TextFieldStyled
                    disabled
                    data-testid="RSimManufacturingFile filename input"
                    sx={{
                      '& fieldset': {
                        ...(!!importStatus?.messages
                          ? { borderColor: `${palette.failed.contrastText} !important` }
                          : {}),
                      },
                      '& input': {
                        ...(selectedFile
                          ? {
                              fillColor: `${typoColor.primary} !important`,
                              '-webkit-text-fill-color': `${typoColor.primary} !important`,
                            }
                          : {}),
                      },
                    }}
                    value={
                      selectedFile?.name ||
                      t('admin.rSimManufacturingFile.rSimManufacturingFilename')
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 3 }}>
                          <PublishOutlinedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ mr: 3, color: palette.failed.contrastText }}
                        >
                          {!!importStatus?.messages && <ErrorOutlineIcon fontSize="small" />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    {importStatus?.messages && (
                      <Typography variant="text4Error" sx={{ ml: 5 }}>
                        {importStatus?.messages[0]}
                      </Typography>
                    )}
                  </Box>

                  <ButtonBox sx={{ marginTop: importStatus?.messages ? '' : '24px' }}>
                    {!selectedFile && (
                      <>
                        <Typography variant="h5Secondary">
                          {t('admin.rSimManufacturingFile.dragAndDropFileOr')}
                        </Typography>

                        <label htmlFor="file-input">
                          <Input
                            id="file-input"
                            name="rSimManufacturingFile file input"
                            data-testid="rSimManufacturingFile file input"
                            type="file"
                            sx={{ display: 'none' }}
                            onChange={(event) => {
                              const fileInput = event.target as HTMLInputElement;
                              if (fileInput?.files?.[0]) {
                                selectFile(fileInput.files[0]);
                                // file input cannot be controlled. This is hack to reset the input field that allow
                                // upload the same file in row
                                event.target.value = '';
                              }
                            }}
                          />
                          <Button
                            data-testid="rSimManufacturingFile browse button"
                            variant="contained"
                            component="span"
                            sx={{ padding: '10px 16px' }}
                          >
                            {t('admin.rSimManufacturingFile.browse')}
                          </Button>
                        </label>
                      </>
                    )}
                    {selectedFile && uploading && (
                      <Button
                        data-testid="rSimManufacturingFile uploading button"
                        variant="contained"
                        component="span"
                        sx={{ padding: '10px 40px' }}
                      >
                        <AutorenewOutlinedIcon fontSize="small" />
                      </Button>
                    )}
                    {selectedFile && !uploading && !importId && (
                      <>
                        <TransparentButtonStyled
                          variant="text"
                          color="tertiary"
                          data-testid="rSimManufacturingFile clear button"
                          onClick={() => clear()}
                        >
                          {t('admin.rSimManufacturingFile.clear')}
                        </TransparentButtonStyled>
                        <ButtonStyled
                          data-testid="rSimManufacturingFile upload button"
                          variant="contained"
                          startIcon={<PublishOutlinedIcon />}
                          onClick={() => uploadFile()}
                          disabled={!!importStatus?.messages}
                        >
                          {t('admin.rSimManufacturingFile.upload')}
                        </ButtonStyled>
                      </>
                    )}
                    {selectedFile && !uploading && importId && (
                      <>
                        <TransparentButtonStyled
                          variant="text"
                          color="tertiary"
                          data-testid="rSimManufacturingFile upload new file button"
                          onClick={() => clear()}
                        >
                          {t('admin.rSimManufacturingFile.uploadNewFile')}
                        </TransparentButtonStyled>
                        <Button
                          data-testid="rSimManufacturingFile download results button"
                          variant="contained"
                          startIcon={<DownloadOutlinedIcon />}
                          onClick={() => downloadOutput()}
                        >
                          {t('admin.rSimManufacturingFile.downloadResults')}
                        </Button>
                      </>
                    )}
                  </ButtonBox>
                </BoxStyled>
              </Container>

              {dropAreaDisplayed && (
                <DropAreaBox>
                  <Typography variant="h1Secondary">{t('common.dropFile')}</Typography>
                </DropAreaBox>
              )}

              <Stack>
                <AutoHideSnackbar
                  open={!!importStatus?.title}
                  severity={importStatus?.severity || 'error'}
                  onClose={() => {
                    setImportStatus((prev) => {
                      return prev ? { ...prev, title: '' } : null;
                    });
                  }}
                >
                  {''} {/* Without this component throw a error and crash app */}
                  <AlertTitle>{importStatus?.title || ''}</AlertTitle>
                  {importStatus?.description && (
                    <Typography variant="text1White">{importStatus?.description}</Typography>
                  )}
                </AutoHideSnackbar>
              </Stack>
            </Box>
          );
        }}
      </Dropzone>
    </>
  );
};
