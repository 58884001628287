import { greyColor, palette, statusColor, typoColor } from './palette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    success?: React.CSSProperties;
    error?: React.CSSProperties;
    pending?: React.CSSProperties;
    light?: React.CSSProperties;

    inputLabel?: React.CSSProperties;
    searchInputLabel?: React.CSSProperties;
    userInfo?: React.CSSProperties;
    tableHeaderPrimary?: React.CSSProperties;
    tableHeaderSecondary?: React.CSSProperties;
    tableCellPrimary?: React.CSSProperties;
    tableCellSecondary?: React.CSSProperties;
    counter?: React.CSSProperties;
    counterSuccess?: React.CSSProperties;
    counterFail?: React.CSSProperties;
    inputError?: React.CSSProperties;
    counterPending?: React.CSSProperties;
    h1Secondary?: React.CSSProperties;
    h4Secondary?: React.CSSProperties;
    h5Secondary?: React.CSSProperties;
    text1?: React.CSSProperties;
    text1Primary: React.CSSProperties;
    text1Error?: React.CSSProperties;
    text2Primary?: React.CSSProperties;
    text2Secondary?: React.CSSProperties;
    text1Secondary?: React.CSSProperties;
    text1White?: React.CSSProperties;
    text5Primary?: React.CSSProperties;
    text5Secondary: React.CSSProperties;
    text3Primary: React.CSSProperties;
    text3Secondary?: React.CSSProperties;
    text3white?: React.CSSProperties;
    text4Primary: React.CSSProperties;
    text4Secondary?: React.CSSProperties;
    text4Error?: React.CSSProperties;
    menuItem?: React.CSSProperties;
    link?: React.CSSProperties;
    h4Primary?: React.CSSProperties;
    h3Secondary?: React.CSSProperties;
    text3?: React.CSSProperties;
    text5?: React.CSSProperties;
    textButton?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    success?: React.CSSProperties;
    error?: React.CSSProperties;
    pending?: React.CSSProperties;
    light?: React.CSSProperties;

    inputLabel?: React.CSSProperties;
    searchInputLabel?: React.CSSProperties;
    userInfo?: React.CSSProperties;
    tableHeaderPrimary?: React.CSSProperties;
    tableHeaderSecondary?: React.CSSProperties;
    tableCellPrimary?: React.CSSProperties;
    tableCellSecondary?: React.CSSProperties;
    counter?: React.CSSProperties;
    counterSuccess?: React.CSSProperties;
    counterFail?: React.CSSProperties;
    inputError?: React.CSSProperties;
    counterPending?: React.CSSProperties;
    h1Secondary?: React.CSSProperties;
    h4Secondary?: React.CSSProperties;
    h5Secondary?: React.CSSProperties;
    text1Primary?: React.CSSProperties;
    text1Secondary?: React.CSSProperties;
    text1White?: React.CSSProperties;
    text1Error?: React.CSSProperties;
    text2Primary?: React.CSSProperties;
    text2Secondary?: React.CSSProperties;
    menuItem?: React.CSSProperties;
    text5Primary?: React.CSSProperties;
    text5Secondary: React.CSSProperties;
    text3Primary: React.CSSProperties;
    text3Secondary?: React.CSSProperties;
    text3white?: React.CSSProperties;
    text4Primary: React.CSSProperties;
    text4Secondary?: React.CSSProperties;
    text4Error?: React.CSSProperties;
    link?: React.CSSProperties;
    h4Primary?: React.CSSProperties;
    h3Secondary?: React.CSSProperties;
    text3?: React.CSSProperties;
    text5?: React.CSSProperties;
    textButton?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    success: true;
    error: true;
    pending: true;
    light: true;

    searchInputLabel: true;
    inputLabel: true;
    userInfo: true;
    tableHeaderPrimary: true;
    tableHeaderSecondary: true;
    tableCellPrimary: true;
    tableCellSecondary: true;
    counter: true;
    counterSuccess: true;
    counterError: true;
    inputError: true;
    counterPending: true;
    h1Secondary: true;
    h4Secondary: true;
    h5Secondary: true;
    text1: true;
    text1Primary: true;
    text1Secondary: true;
    text1White: true;
    text1Error: true;
    text1Strong: true;
    text2Primary: true;
    text2Secondary: true;
    menuItem: true;
    text5Primary: true;
    text5Secondary: true;
    text3Primary: true;
    text3Secondary: true;
    text3white: true;
    text4Primary: true;
    text4Secondary: true;
    text4Error: true;
    link: true;
    h4Primary: true;
    h3Secondary: true;
    text3: true;
    text5: true;
    textButton: true;
  }
}

export const fontFamily = 'Hind, Arial';

export const typographyBase = {
  h1: {
    fontSize: '28px',
    lineHeight: '48px',
    fontWeight: 600,
    fontFamily,
  },
  h2: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 600,
    fontFamily,
  },
  h3: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily,
  },
  h4: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    fontFamily,
  },
  h5: {
    fontSize: '16px',
    lineHeight: '25px',
    fontWeight: 500,
    fontFamily,
  },
  text1: {
    fontSize: '13px',
    lineHeight: '21px',
    fontWeight: 400,
    fontFamily,
  },
  text2: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    fontFamily,
  },
  text3: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    fontFamily,
  },
  text4: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily,
  },
  text5: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily,
  },
  button: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    fontFamily,
  },
};

export const typography = {
  fontFamily: 'Hind',
  ...typographyBase,
  h1Secondary: {
    ...typographyBase.h1,
    color: typoColor.secondary,
  },
  h4Secondary: {
    ...typographyBase.h4,
    color: typoColor.secondary,
  },
  h5Secondary: {
    ...typographyBase.h5,
    color: typoColor.secondary,
  },
  text1: {
    ...typographyBase.text1,
  },
  text1Primary: {
    ...typographyBase.text1,
    color: typoColor.primary,
  },
  text1Secondary: {
    ...typographyBase.text1,
    color: typoColor.secondary,
  },
  text1Error: {
    ...typographyBase.text1,
    color: palette.failed.contrastText,
  },
  text1White: {
    ...typographyBase.text1,
    color: typoColor.white,
  },
  text1Strong: {
    ...typographyBase.text1,
    fontWeight: 700,
  },
  text2Primary: {
    ...typographyBase.text2,
    color: typoColor.primary,
  },
  text2Secondary: {
    ...typographyBase.text2,
    color: typoColor.secondary,
  },
  text3: {
    ...typographyBase.text3,
  },
  text3Primary: {
    ...typographyBase.text3,
    color: typoColor.primary,
  },
  text3Secondary: {
    ...typographyBase.text3,
    color: typoColor.secondary,
  },
  text3white: {
    ...typographyBase.text3,
    color: typoColor.white,
  },
  text4Primary: {
    ...typographyBase.text4,
    color: typoColor.primary,
  },
  text4Secondary: {
    ...typographyBase.text4,
    color: typoColor.secondary,
  },
  text4Error: {
    ...typographyBase.text4,
    color: palette.failed.contrastText,
  },
  text5: {
    ...typographyBase.text5,
  },
  text5Primary: {
    ...typographyBase.text5,
    color: typoColor.primary,
  },
  text5Secondary: {
    ...typographyBase.text5,
    color: typoColor.secondary,
  },
  inputLabel: {
    ...typographyBase.text2,
    color: typoColor.secondary,
  },
  inputError: {
    ...typographyBase.text4,
    color: palette.failed.contrastText,
  },
  searchInputLabel: {
    ...typographyBase.text5,
    color: typoColor.secondary,
  },
  tableHeaderPrimary: {
    ...typographyBase.text3,
    color: typoColor.primary,
  },
  tableHeaderSecondary: {
    ...typographyBase.text4,
    color: typoColor.secondary,
  },
  tableCellPrimary: {
    ...typographyBase.text1,
    verticalAlign: 'center',
    color: typoColor.primary,
    cursor: 'text',
  },
  tableCellSecondary: {
    ...typographyBase.text5,
    lineHeight: 1,
    color: typoColor.secondary,
    cursor: 'text',
  },
  counter: {
    ...typographyBase.text1,
    color: typoColor.primary,
  },
  counterSuccess: {
    ...typographyBase.text1,
    color: statusColor.success,
  },
  counterError: {
    ...typographyBase.text1,
    color: statusColor.error,
  },
  light: {
    ...typographyBase.text4,
    color: greyColor[500],
  },
  userInfo: {
    ...typographyBase.text3,
    color: typoColor.primary,
  },
  menuItem: {
    fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  link: {
    ...typographyBase.text1,
    verticalAlign: 'center',
    color: greyColor[700],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  h4Primary: {
    ...typographyBase.h4,
    color: typoColor.primary,
  },
  h3Secondary: {
    ...typographyBase.h3,
    color: typoColor.secondary,
  },
  textButton: {
    ...typographyBase.button,
    color: typoColor.secondary,
  },
};
