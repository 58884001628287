import { ValuesType } from 'utility-types';
import { SIM_CARD_IDENTIFIERS } from 'data/sim.constants';
import { useRSimFilters } from 'rSimInventory/Filters/hooks/useRSimFilters';
import { RSimIdSearchInput } from 'rSimInventory/Components/Inputs/RSimIdSearchInput';

export const RSIM_INVENTORY_ID_SELECTION = {
  EID: SIM_CARD_IDENTIFIERS.EID,
  ICCID: SIM_CARD_IDENTIFIERS.ICCID,
} as const;

export type RSimId = ValuesType<typeof RSIM_INVENTORY_ID_SELECTION>;

export const RSimIdFiltersSearchInput = () => {
  const { filters, updateOne } = useRSimFilters();

  const chosenId = filters.chosenIdType;
  const onChosenIdChange = function (chosenId: 'EID' | 'ICCID'): void {
    updateOne('chosenIdType', chosenId);
  };
  const search = filters.searchText;
  const onSearchChange = (value: string) => {
    updateOne('searchText', value);
  };

  return (
    <RSimIdSearchInput
      chosenId={chosenId}
      onChosenIdChange={onChosenIdChange}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};
