import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { AuthError } from '../../auth/utils';
import {
  downloadOutputForRSimImport,
  uploadRSimManufacturingFile,
} from './api/rSimManufacturingFileApi';
import { AlertColor } from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

export type Status = {
  title: string;
  description?: string;
  messages?: string[];
  severity: AlertColor;
};

export const useRSimUpload = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File | null>(null);
  const [importId, setImportId] = useState<string | null>(null);
  const [importStatus, setImportStatus] = useState<Status | null>(null);

  const { mutate: uploadFileMutation, isLoading } = useMutation(
    async (file: File) => {
      if (!isLoading) {
        const response = await uploadRSimManufacturingFile(file);
        setImportId(response.outputId.value);
        if (!response.hasErrors) {
          return setImportStatus({
            //@ts-ignore
            title: t('admin.rSimManufacturingFile.fileProcessedSuccessfully', {
              filename: file.name,
            }),
            severity: 'success',
          });
        } else {
          return setImportStatus({
            //@ts-ignore
            title: t('admin.rSimManufacturingFile.fileProcessed', { filename: file.name }),
            description: t('admin.rSimManufacturingFile.downloadTheResultsFileToSeeDetails'),
            severity: 'info',
          });
        }
      }
    },
    {
      onSuccess: () => {},
      onError: (error) => {
        if (error instanceof AuthError) {
          throw error;
        } else {
          // @ts-ignore
          const parsedError = JSON.parse(error.message);
          if (parsedError?.validationErrors) {
            const messages: string[] = [];
            for (const key in parsedError.validationErrors) {
              messages.push(parsedError.validationErrors[key][0]?.message);
            }
            return setImportStatus({
              //@ts-ignore
              title: t('admin.rSimManufacturingFile.fileUploadFailed', { filename: file.name }),
              messages,
              severity: 'error',
            });
          }
          return setImportStatus({
            //@ts-ignore
            title: t('admin.rSimManufacturingFile.fileUploadFailed', { filename: file.name }),
            severity: 'error',
          });
        }
      },
    },
  );

  const { mutateAsync: downloadOutputMutation } = useMutation(
    ['rSimManufacturingFile', 'downloadOutput'],
    (importId: string) => {
      return downloadOutputForRSimImport(importId, file?.name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rSimManufacturingFile', 'downloadOutput']);
      },
      onError: (error) => {
        if (error instanceof AuthError) {
          throw error;
        } else {
          setImportStatus({
            title: t('admin.rSimManufacturingFile.failedToDownloadTheFile'),
            severity: 'error',
          });
        }
      },
    },
  );

  const uploadFile = () => {
    if (file) {
      uploadFileMutation(file);
    }
  };

  const downloadOutput = () => {
    if (importId) {
      downloadOutputMutation(importId);
    }
  };

  const clear = () => {
    setFile(null);
    setImportId(null);
    setImportStatus(null);
  };

  return {
    selectedFile: file,
    selectFile: setFile,
    uploadFile,
    importStatus,
    setImportStatus,
    uploading: isLoading,
    downloadOutput,
    importId,
    clear,
  };
};
