import { TextCell } from 'common/components/ConfigurableTable/Components/Cells/TextCell';
import { Configuration } from 'common/components/ConfigurableTable/hooks/usePrepareConfiguration';
import { SecondaryConfigurableTable } from 'common/components/ConfigurableTable/SecondaryConfigurableTable';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RsimProfileStatus } from 'rSimInventory/RSimApi/rSimApi.interface';
import { ValuesTypes } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { PollingSimStatus, RSimProfile } from '../PollingSimStatusIcon.interface';
import styled from '@emotion/styled';
import { RSimSimPollingStatusCell } from '../Cells/RSimSimPollingStatusCell';
import { SimulateOutageCell } from '../Cells/SimulateOutageCell';
import { getSessionStatusTranslatedLabel, SessionStatusUi } from 'simInventory/utils/sessionStatus';
import { Routes } from 'Routes.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { RSimSimIccidLinkCell } from './RSimSimIccidLinkCell';
import { SimInventoryEditDialog } from './SimInventoryEditDialog';
import { DurationCell } from 'common/components/ConfigurableTable/Components/Cells/DurationCell';
import { useKickSimContext } from '../KickRsimProvider';
import { SimUpdateProvider } from './SimUpdateProvider';
import { CARD_TYPE, TagProvider } from 'tags/TagProvider';
import { Actions, Subjects } from 'permissions/ability';
import { useRSimsSimsContext } from 'rSimInventory/hooks/useRSimsSimsContext';

type RSimSimTableProps = {
  euicc: string;
  primaryIccid: string;
  secondaryIccid: string;
  status: RsimProfileStatus;
};

const RSimSimConfigurableTable = styled(SecondaryConfigurableTable)({
  '& .MuiDataGrid-cell[data-field="status"]': {
    border: 'none',
  },
});

const FORMATERS_MAP: Partial<Record<ValuesTypes, (value: any) => string>> = {
  sessionStatus: (value: any) => getSessionStatusTranslatedLabel(value as SessionStatusUi, '-'),
};

const SIZE_CONFIGURATION: Partial<
  Record<ValuesTypes, { width?: number; minWidth?: number; maxWidth?: number }>
> = {
  iccid: {
    width: 180,
  },
  currentIpAddress: {
    width: 100,
  },
  commsPlan: {
    width: 240,
  },
  subscriptionStatus: {
    width: 150,
  },
  msisdn: {
    width: 150,
  },
  liveDataUsage: {
    width: 150,
  },
  inMinuteUsage: {
    width: 150,
  },
  inTextUsage: {
    width: 150,
  },
  sessionStartTime: {
    width: 150,
  },
  connectionId: {
    width: 150,
  },
};

export const RSimSimTable = ({
  euicc,
  primaryIccid,
  secondaryIccid,
  status,
}: RSimSimTableProps) => {
  const { t } = useTranslation();

  const idLabelMap: Partial<Record<ValuesTypes, string>> = useMemo(
    () => ({
      iccid: t('common.iccid'),
      imsi: t('common.imsi'),
      msisdn: t('common.msisdn'),
      currentIpAddress: t('common.currentIp'),
      managementIpAddress: t('common.managementIp'),
      mnoRatePlan: t('common.plan'),
      organisation: t('common.organisation'),
      label: t('common.label'),
      subscriptionStatus: t('common.subscriptionStatus'),
      subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_combined'),
      customerCommsPlan: t('simInventory.customerCommsPlan'),
      commsPlan: t('simInventory.commsPlan'),
      mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
      accountName: t('common.accountName'),
      accountRef: t('common.accountNumber'),
      simStatus: t('simInventory.simStatus'),
      liveDataUsage: t('common.usage'),
      inMinuteUsage: t('common.inMinuteUsage'),
      outMinuteUsage: t('common.outMinuteUsage'),
      inTextUsage: t('common.inTextUsage'),
      outTextUsage: t('common.outTextUsage'),
      sessionStartTime: t('simInventory.sessionStartTime'),
      sessionEndTime: t('simInventory.sessionEndTime'),
      sessionStatus: t('simInventory.sessionStatus'),
      sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_combined'),
      connectionId: t('simInventory.connectionId'),
      orderNumber: t('simInventory.orderNumber'),
      tags: t('common.tag'),
    }),
    [],
  );

  const { data } = useRSimsSimsContext();
  const sims = data?.sims;
  const headers = data?.headers;

  const simsProfiles = sims?.[euicc];

  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();

  const simInventoryWithStatus = simsProfiles
    ?.reduce((sorted: any[], item: any) => {
      if (sorted.length === 0) {
        return [item];
      } else if (item?.iccid === primaryIccid) {
        return [item, ...sorted];
      }

      return [...sorted, item];
    }, [])
    .map((item: any) => {
      //Primary profile
      if (item.iccid === primaryIccid && status === RsimProfileStatus.PrimaryProfileActive) {
        return {
          ...item,
          status: PollingSimStatus.Active,
          profile: RSimProfile.Primary,
          paused: isPrimaryProfileDisabled(euicc),
          profileId: 0,
          euicc,
        };
      }

      if (item.iccid === primaryIccid && status === RsimProfileStatus.SecondaryProfileActive) {
        return {
          ...item,
          status: PollingSimStatus.Inactive,
          profile: RSimProfile.Primary,
          paused: isPrimaryProfileDisabled(euicc),
          profileId: 0,
          euicc,
        };
      }

      if (item.iccid === primaryIccid && status === RsimProfileStatus.PrimaryProfileDisconnected) {
        return {
          ...item,
          status: PollingSimStatus.Disconnected,
          profile: RSimProfile.Primary,
          paused: isPrimaryProfileDisabled(euicc),
          profileId: 0,
          euicc,
        };
      }

      if (
        item.iccid === primaryIccid &&
        status === RsimProfileStatus.SecondaryProfileDisconnected
      ) {
        return {
          ...item,
          status: PollingSimStatus.Inactive,
          profile: RSimProfile.Primary,
          profileId: 0,
          paused: isPrimaryProfileDisabled(euicc),
          euicc,
        };
      }

      if (item.iccid === primaryIccid) {
        return {
          ...item,
          status: PollingSimStatus.Unknown,
          paused: isPrimaryProfileDisabled(euicc),
          profileId: 0,
          euicc,
        };
      }

      //Backup profile
      if (item.iccid === secondaryIccid && status === RsimProfileStatus.PrimaryProfileActive) {
        return {
          ...item,
          status: PollingSimStatus.Inactive,
          profile: RSimProfile.Backup,
          paused: isSecondaryProfileDisabled(euicc),
          profileId: 1,
          euicc,
        };
      }

      if (item.iccid === secondaryIccid && status === RsimProfileStatus.SecondaryProfileActive) {
        return {
          ...item,
          status: PollingSimStatus.Active,
          profile: RSimProfile.Backup,
          paused: isSecondaryProfileDisabled(euicc),
          profileId: 1,
          euicc,
        };
      }

      if (
        item.iccid === secondaryIccid &&
        status === RsimProfileStatus.PrimaryProfileDisconnected
      ) {
        return {
          ...item,
          status: PollingSimStatus.Inactive,
          profile: RSimProfile.Backup,
          paused: isSecondaryProfileDisabled(euicc),
          profileId: 1,
          euicc,
        };
      }

      if (
        item.iccid === secondaryIccid &&
        status === RsimProfileStatus.SecondaryProfileDisconnected
      ) {
        return {
          ...item,
          status: PollingSimStatus.Disconnected,
          profile: RSimProfile.Backup,
          profileId: 1,
          paused: isSecondaryProfileDisabled(euicc),
          euicc,
        };
      }

      if (item.iccid === secondaryIccid) {
        return {
          ...item,
          status: PollingSimStatus.Unknown,
          paused: isSecondaryProfileDisabled(euicc),
          profileId: 1,
          euicc,
        };
      }

      return { ...item, status: PollingSimStatus.Unknown, euicc };
    });

  const configuration: Configuration[] = useMemo(() => {
    const staticHeadersBegining: Configuration[] = [
      {
        field: 'status',
        headerName: '',
        width: 50,
        resizable: false,
        sortable: false,
        pinnable: false,
      },
    ];

    const obtainedHeaders =
      headers?.map((header: any) => {
        const field = header.types[0] as ValuesTypes;

        const baseConfiguration: Configuration = {
          field: field,
          headerName: idLabelMap[field],
          //@ts-ignore
          valueGetter: FORMATERS_MAP[field],
          resizable: false,
          sortable: false,
          pinnable: false,
          width: SIZE_CONFIGURATION[field]?.width,
          minWidth: SIZE_CONFIGURATION[field]?.minWidth,
          maxWidth: SIZE_CONFIGURATION[field]?.maxWidth,
        };

        if (header.types[1]) {
          const subField = header.types[1] as ValuesTypes;
          baseConfiguration.subField = {
            field: subField,
            headerName: idLabelMap[subField] || subField,
            valueGetter: FORMATERS_MAP[subField],
          };
        }

        return baseConfiguration;
      }) || [];

    const staticHeadersEnd: Configuration[] = [
      {
        field: 'simulateOutage',
        headerName: t('common.simulateOutage'),
        width: 150,
        resizable: false,
        sortable: false,
        pinnable: false,
        permission: {
          action: Actions.simulate,
          subject: Subjects.outage,
        },
      },
    ];

    return [...staticHeadersBegining, ...obtainedHeaders, ...staticHeadersEnd];
  }, [headers]);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <div data-testid="sims-inventory-table">
      <TagProvider type={CARD_TYPE.SIM}>
        <SimUpdateProvider queryKey={['RSimSimProfiles', euicc]}>
          <RSimSimConfigurableTable
            data-testid={`sim-inventory-${euicc}`}
            rows={simInventoryWithStatus || []}
            actions={[
              {
                label: 'View Details',
                type: 'custom',
                actionCallback: (data: any) => {
                  navigate(`${Routes.simInventory}/${data.iccid}`, {
                    state: {
                      previousPath: `${pathname}${search}`,
                    },
                  });
                },
              },
              {
                id: 'edit',
                label: 'Edit',
                type: 'dialog',
                Component: SimInventoryEditDialog,
                // permission: {
                //   action: Actions.edit,
                //   subject: Subjects.tags,
                // },
              },
            ]}
            configuration={configuration}
            disablePagination
            getRowId={(row) => row.iccid}
            cellConfiguration={{
              default: TextCell,
              iccid: RSimSimIccidLinkCell,
              status: RSimSimPollingStatusCell,
              simulateOutage: SimulateOutageCell,
              inMinuteUsage: DurationCell,
            }}
          />
        </SimUpdateProvider>
      </TagProvider>
    </div>
  );
};
