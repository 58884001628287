import { useEffect, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Select } from 'common/components/Inputs/Select/Select';

import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog } from 'common/components/Filters/FiltersDialog';
import { FiltersButton } from 'common/components/Filters/FiltersButton';

import { FilterListItem } from 'common/components/Filters/FilterListItem';
import {
  FILTER_SIM_CARD_IDENTIFIERS,
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  LastUpdateTimeFilters,
  RSIM_INITIAL_FILTERS,
  SIMULATE_OUTAGE_FILTER,
} from './data/filters.constants';
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleGroup } from 'common/components/Inputs/ToggleGroup/ToggleGroup';
import Box from '@mui/material/Box';
import {
  AutocompleteSelect,
  Option,
} from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useQuery } from 'react-query';
import { convertStringToTags } from 'tags/utils';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { fetchAccounts } from './services/accountsApi';
import { useDebounce } from '../../common/useDebounce';
import {
  mapOptionsToString,
  TextFieldWithHints,
} from 'common/components/Inputs/TextFieldWithHints/TextFieldWithHints';
import { RSimIdSearchInput } from 'rSimInventory/Components/Inputs/RSimIdSearchInput';
import { IdRangeSearch } from 'common/components/Inputs/IdRangeSearch/IdRangeSearch';
import { RadioSection } from 'common/components/Filters/IdFilterSelector/RadioSection';
import { FilterBox } from 'common/components/Filters/FilterBox';
import { useRSimInventoryFiltersModal } from './hooks/useRSimInventoryFiltersModal';
import { useTagsWithHints } from '../../common/components/Filters/useTagWithHints';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { useRSimFilters } from './hooks/useRSimFilters';

const MAX_ACCOUNTS_TO_FILTER = 10;

const filterArray = (options: Option[]) => {
  return options.reduce((p: Option[], c: Option) => {
    if (!p.some((el: Option) => el.id === c.id)) {
      p.push(c);
    }
    return p;
  }, []);
};

export const RSimFiltersModal = () => {
  const { filters, updateAll } = useRSimFilters();

  const { setTempHints, removeHint, hints, applyHints, tagsData, refetchTags } = useTagsWithHints();
  const { filtersActive, open, setOpen, apply, cancel, clear, modalFilters, setModalFilters } =
    useRSimInventoryFiltersModal(filters, updateAll);
  const { t } = useTranslation();

  const [accountFilter, setAccountFilter] = useState('');
  const [accountsChosen, setAccountsChosen] = useState<Option[]>();
  const [initialOpen, setInitialOpen] = useState(true);

  const { errors, warnings } = useMemo(() => {
    let errors: string[] = [];
    let warnings: string[] = [];
    if (accountsChosen && accountsChosen.length >= MAX_ACCOUNTS_TO_FILTER + 1) {
      errors = [t('common.limitReached')];
      warnings = [];
    } else if (accountsChosen && accountsChosen.length >= MAX_ACCOUNTS_TO_FILTER) {
      errors = [];
      warnings = [t('common.limitReached')];
    }

    return { errors, warnings };
  }, [accountsChosen]);

  const { data: accountsData, refetch: refetchAccountsQuery } = useQuery(
    ['accounts', accountFilter],
    async () => {
      const accounts = await fetchAccounts(accountFilter);

      const accountOptions = accounts.map((account) => ({
        id: account.accountRef.toString(),
        name: account.accountName,
        toAdd: false,
        showBothIdAndName: true,
      }));

      if (open && initialOpen) {
        const currentlySelected = convertStringToTags(modalFilters.accounts);
        const acc = accountOptions?.filter((a) => currentlySelected.includes(a.id));
        setAccountsChosen(acc || []);
        setInitialOpen(false);
      }

      return accountOptions;
    },
    {
      suspense: false,
      enabled: false,
    },
  );

  useEffect(() => {
    refetchTags();
    refetchAccountsQuery();
  }, [open]);

  const debounceAccount = useDebounce(accountFilter, 500);

  useEffect(() => {
    refetchAccountsQuery();
  }, [debounceAccount]);

  const tags = useMemo(() => {
    return convertStringToTags(modalFilters.tags);
  }, [modalFilters.tags]);

  const accounts = useMemo(() => {
    return convertStringToTags(modalFilters.accounts);
  }, [modalFilters.accounts]);

  useEffect(() => {
    setAccountsChosen((prevState) => {
      return prevState?.filter((item) => accounts.includes(item.id));
    });
  }, [accounts]);

  const [selectedSearch, setSelectedSearch] = useState<'search' | 'eidRange'>('search');
  useEffect(() => {
    if (selectedSearch === 'search') {
      setModalFilters((currentFilters) => ({
        ...currentFilters,
        eidFrom: '',
        eidTo: '',
      }));
    } else if (selectedSearch === 'eidRange') {
      setModalFilters((currentFilters) => ({
        ...currentFilters,
        searchText: '',
        chosenIdType: RSIM_INITIAL_FILTERS.chosenIdType,
      }));
    }
  }, [selectedSearch]);

  return (
    <>
      <FiltersButton onClick={() => setOpen(true)} active={filtersActive} />
      <FiltersDialog
        open={open}
        onApply={() => {
          apply();
          applyHints();
        }}
        onCancel={cancel}
        onClear={clear}
        onClose={() => {
          cancel();
          setOpen(false);
        }}
      >
        <FilterSection title={t('common.idSearch')}>
          <FilterBox>
            <RadioSection
              options={['search', 'eidRange']}
              value={selectedSearch}
              onValueChange={(newValue) => setSelectedSearch(newValue as 'search' | 'eidRange')}
              labels={{
                search: 'Search',
                eidRange: 'EID Range',
              }}
            >
              {(value) => {
                return (
                  <Box>
                    <Box
                      data-selector="search"
                      sx={{ opacity: value === 'search' ? 1 : 0 }}
                      aria-hidden={value !== 'search'}
                    >
                      <RSimIdSearchInput
                        chosenId={modalFilters.chosenIdType}
                        onChosenIdChange={function (chosenId: 'EID' | 'ICCID'): void {
                          try {
                            //@ts-ignore
                            setModalFilters((prevFilters) => ({
                              ...prevFilters,
                              chosenIdType: chosenId,
                            }));
                          } catch (err) {
                            setModalFilters((prevFilters) => ({
                              ...prevFilters,
                              chosenIdType: FILTER_SIM_CARD_IDENTIFIERS.ICCID,
                            }));
                          }
                        }}
                        search={modalFilters.searchText}
                        onSearchChange={function (value: string): void {
                          const newValue = value;

                          try {
                            setModalFilters((prevFilters) => ({
                              ...prevFilters,
                              searchText: newValue,
                            }));
                          } catch (err) {
                            setModalFilters((prevFilters) => ({
                              ...prevFilters,
                              searchText: '',
                            }));
                          }
                        }}
                      />
                    </Box>
                    <Box
                      data-selector="eidRange"
                      sx={{ opacity: value === 'eidRange' ? 1 : 0 }}
                      aria-hidden={value !== 'eidRange'}
                    >
                      <IdRangeSearch
                        data-testid="eid range search"
                        from={modalFilters.eidFrom}
                        to={modalFilters.eidTo}
                        onChange={(from: string, to: string) => {
                          setModalFilters((prevFilters) => ({
                            ...prevFilters,
                            eidFrom: from,
                            eidTo: to,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                );
              }}
            </RadioSection>
          </FilterBox>
        </FilterSection>
        <FilterSection title={'rSim'}>
          <FilterListItem label="Last active profile" htmlFor="lastActiveProfile">
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ToggleGroup
                sx={{
                  width: '100%',
                  justifyContent: 'stretch',
                }}
                value={modalFilters.lastActiveProfile}
                onChange={(_event, value) => {
                  if (value === null) {
                    return;
                  }

                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    lastActiveProfile: value,
                  }));
                }}
              >
                <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.PRIMARY}>
                  Primary
                </ToggleButton>
                <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.ANY}>
                  Any
                </ToggleButton>
                <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.SECONDARY}>
                  Secondary
                </ToggleButton>
              </ToggleGroup>
            </Box>
          </FilterListItem>
          <FilterListItem label="No update for" htmlFor="noUpdateFor">
            <Select
              name="noUpdateFor"
              value={modalFilters.lastUpdateTime}
              onChange={(event) => {
                const value = event.target.value as LastUpdateTimeFilters;
                try {
                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    lastUpdateTime: value,
                  }));
                } catch (err) {
                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    lastUpdateTime: LAST_UPDATE_TIME_FILTERS.NONE,
                  }));
                }
              }}
            >
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.NONE}>None</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.MINUTES_15}>15 minutes</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.MINUTES_30}>30 minutes</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.HOUR}>1 hour</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.DAY}>1 day</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.WEEK}>1 week</MenuItem>
              <MenuItem value={LAST_UPDATE_TIME_FILTERS.MONTH}>1 month</MenuItem>
            </Select>
          </FilterListItem>
          <FilterListItem label={t('common.simulateOutage')} htmlFor="simulateOutage">
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ToggleGroup
                sx={{
                  width: '100%',
                  justifyContent: 'stretch',
                }}
                value={modalFilters.simulateOutage}
                onChange={(_event, value) => {
                  if (value === null) {
                    return;
                  }

                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    simulateOutage: value,
                  }));
                }}
              >
                <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.ON}>
                  {t('common.on')}
                </ToggleButton>
                <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.ANY}>
                  {t('common.any')}
                </ToggleButton>
                <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.OFF}>
                  {t('common.off')}
                </ToggleButton>
              </ToggleGroup>
            </Box>
          </FilterListItem>
          <SimInventoryCan I={Actions.filter} a={Subjects.internalData}>
            <FilterListItem label="Account" htmlFor="account">
              <AutocompleteSelect
                testId="accounts-multiselect"
                hints={hints?.accounts}
                errors={errors}
                warnings={warnings}
                values={accounts}
                options={filterArray([...(accountsData || []), ...(accountsChosen || [])])}
                onChange={(values) => {
                  setModalFilters((prevFilters) => ({
                    ...prevFilters,
                    accounts: values.map((value) => value.id).join(','),
                  }));
                  setTempHints((prevHints) => ({ ...prevHints, accounts: values }));
                  setAccountsChosen(values);
                }}
                onRemoveHint={(hint: any) => removeHint(hint, 'accounts')}
                allowAdding={false}
                inputValue={accountFilter}
                onTextInputChange={(val) => setAccountFilter(val)}
                allowFilteringById={true}
                placeholder={
                  modalFilters.accounts.length > 0
                    ? ''
                    : t('rSimInventory.accountFilterPlaceholder')
                }
              />
            </FilterListItem>
          </SimInventoryCan>
          <FilterListItem label="Tag" htmlFor="tag">
            <AutocompleteSelect
              testId="tags-multiselect"
              hints={hints?.tags}
              values={tags}
              options={tagsData || []}
              onChange={(values) => {
                setModalFilters((prevFilters) => ({
                  ...prevFilters,
                  tags: values.map((value) => value.id).join(','),
                }));
                setTempHints((prevHints) => ({ ...prevHints, tags: values }));
              }}
              onRemoveHint={(hint: any) => removeHint(hint, 'tags')}
              allowAdding={false}
              placeholder={
                modalFilters.tags.length > 0 ? '' : t('rSimInventory.tagsFilterPlaceholder')
              }
            />
          </FilterListItem>
        </FilterSection>

        <Box>
          <FilterSection title={t('common.order')}>
            <List>
              <FilterListItem label={t('common.connectionId')} htmlFor="connectionId">
                <TextFieldWithHints
                  testId="connectionId text field"
                  hints={hints?.connectionId ? mapOptionsToString(hints.connectionId) : []}
                  value={modalFilters.connectionId}
                  onChange={(value) => {
                    setModalFilters((prevState) => ({
                      ...prevState,
                      connectionId: value || '',
                    }));
                    setTempHints((prevHints) => ({
                      ...prevHints,
                      connectionId: [{ id: value || '' }],
                    }));
                  }}
                  onRemoveHint={(hint: string) => removeHint({ id: hint }, 'connectionId')}
                  placeholder={t('rSimInventory.connectionIdFilterPlaceholder')}
                />
              </FilterListItem>
              <FilterListItem label={t('simInventory.orderNumber')} htmlFor="orderNumber">
                <TextFieldWithHints
                  testId="orderNumber text field"
                  hints={hints?.orderNumber ? mapOptionsToString(hints.orderNumber) : []}
                  value={modalFilters.orderNumber}
                  onChange={(value) => {
                    setModalFilters((prevState) => ({
                      ...prevState,
                      orderNumber: value || '',
                    }));
                    setTempHints((prevHints) => ({
                      ...prevHints,
                      orderNumber: [{ id: value || '' }],
                    }));
                  }}
                  onRemoveHint={(hint: string) => removeHint({ id: hint }, 'orderNumber')}
                  placeholder={t('rSimInventory.orderNumberFilterPlaceholder')}
                />
              </FilterListItem>
            </List>
          </FilterSection>
        </Box>
      </FiltersDialog>
    </>
  );
};
