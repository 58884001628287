import React from 'react';
import { useRSimFiltersParams } from './hooks/useRSimFiltersParams';

export const RSimFiltersContext = React.createContext({} as any);

export type RSimFiltersProviderProps = {
  children: React.ReactNode;
};

export const RSimFiltersProvider = ({ children }: RSimFiltersProviderProps) => {
  const { filters, updateOne, updateAll, clearAll, clearOne, clearMany } = useRSimFiltersParams();
  return (
    <RSimFiltersContext.Provider
      value={{
        filters,
        updateOne,
        updateAll,
        clearAll,
        clearOne,
        clearMany,
      }}
    >
      {children}
    </RSimFiltersContext.Provider>
  );
};
