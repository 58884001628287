import { getI18n } from 'react-i18next';

import { Configuration } from 'common/components/ConfigurableTable/hooks/usePrepareConfiguration';

const i18n = getI18n();

export const RSIM_TABLE_CONFIGURATION: Configuration[] = [
  {
    field: 'euicc',
    headerName: i18n.t('common.eid'),
    resizable: false,
    sortable: true,
    pinnable: false,
    minWidth: 300,
  },
  {
    field: 'rSimStatus',
    headerName: i18n.t('rSimInventory.rsimStatus'),
    resizable: false,
    sortable: false,
    pinnable: false,
  },
  {
    field: 'lastPollingTimestamp',
    headerName: i18n.t('rSimInventory.lastPollingTimestamp'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
  },
  {
    field: 'accountName',
    headerName: i18n.t('common.accountName'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
    subField: {
      field: 'accountNumber',
      headerName: i18n.t('common.accountNumber'),
    },
  },
  {
    field: 'dataUsage',
    headerName: i18n.t('common.usage'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
  },
  {
    field: 'inMinuteUsage',
    headerName: i18n.t('common.inMinuteUsage'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
    subField: {
      field: 'outMinuteUsage',
      headerName: i18n.t('common.outMinuteUsage'),
    },
  },
  {
    field: 'inTextUsage',
    headerName: i18n.t('common.inTextUsage'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
    subField: {
      field: 'outTextUsage',
      headerName: i18n.t('common.outTextUsage'),
    },
  },
  {
    field: 'appliedOtaConfigurationProfile',
    headerName: i18n.t('rSimInventory.configuration'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
    subField: {
      field: 'appliedOtaConfigurationVersion',
      headerName: i18n.t('rSimInventory.version'),
    },
  },
  {
    field: 'tags',
    headerName: i18n.t('common.tag'),
    resizable: false,
    sortable: false,
    pinnable: false,
    minWidth: 300,
  },
];
