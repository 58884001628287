import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getUpdatedUrlSearchParams } from 'common/utils/urlSearchParamsUtils';
import { ID_SEARCH_LIMIT } from '../Components/Inputs/SimIdSearchInput';
import { ID_SEARCH_REGEX } from '../../common/components/Inputs/IdAdvanceSearch/MultipleIdInput';

export const useMultipleIdSearch = <T extends string>(
  defaultId: string,
  initialSearchQuery: string = '',
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchText = searchParams.get('searchText');

  const [searchQueryV1, setSearchQueryV1] = useState<string>(() => {
    if (urlSearchText) {
      return urlSearchText;
    }

    if (initialSearchQuery) {
      return initialSearchQuery;
    }

    return '';
  });

  const ids = useMemo(() => {
    const results = searchQueryV1.match(ID_SEARCH_REGEX) || [];
    return results.slice(0, ID_SEARCH_LIMIT);
  }, [searchQueryV1]);

  const idType: T = (searchParams.get('chosenIdType') || defaultId) as T;

  const updateIdType = (idType: T) => {
    setSearchParams(getUpdatedUrlSearchParams('chosenIdType', idType, searchParams), {
      replace: true,
    });
  };

  const updateSearchQuery = (searchQuery: string) => {
    setSearchQueryV1(searchQuery);
  };

  return {
    ids,
    idType,

    updateIdType,
    searchQuery: searchQueryV1,
    urlSearchText,
    updateSearchQuery,
  };
};
